export function gift_alert(templateId) {

    // Android 모바일 기기에서만 적용하는 조건
    const isAndroidMobile = /Android/i.test(navigator.userAgent) && /Mobile/i.test(navigator.userAgent) && window.innerWidth <= 768;

    document.addEventListener('scroll', function() {
        const floatingBtn = document.getElementById('invitationView_flower_floatingBtn');
        if (window.scrollY > 0) {
            // Android 모바일 기기에서만 위치 조정
            floatingBtn.style.bottom = isAndroidMobile ? '80px' : '24px';
        } else {
            floatingBtn.style.bottom = '-64px'; // 위로 스크롤 시 숨김
        }
    });

    console.log(document.getElementById('editView_flower_floatingBtn'));


    // gift_section_graphic을 클릭할 때
    document.querySelector('.gift_section_graphic').addEventListener('click', function () {
        document.getElementById('alert_gift').classList.toggle('is-active');
    });

    // flower_floatingBtn을 클릭할 때
    document.querySelectorAll('.flower_floatingBtn').forEach(function (element) {
        element.addEventListener('click', function () {
            document.getElementById('alert_gift').classList.toggle('is-active');
        });
    });


    // CloseAlert_gift를 클릭할 때
    document.getElementById('CloseAlert_gift').addEventListener('click', function () {
        document.getElementById('alert_gift').classList.toggle('is-active');
    });

    // let giftButton = document.querySelector('.gift_item_inner_button');
    // giftButton.addEventListener('click', function () {
    //     window.open('https://agent.maruw.co.kr/mobile/product?agency_idx=2679&user_idx=2930&goods_div_idx=1', '_blank');
    // });

    let giftButton = document.querySelector('.gift_item_inner_button');

    giftButton.addEventListener('click', function () {

        const data = {
            idx: templateId, // 청첩장 고유 번호로 사용되는 템플릿 ID
            // 추가로 필요한 데이터가 있다면 여기에 포함
        };

        // 서버로 POST 요청 보내기
        fetch(`/api_flower_callback/${templateId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(data => {
                console.log('Success:', data);
                const isProduction = window.location.hostname !== 'localhost';
                const callbackUrl = isProduction
                    ? `https://www.moiitee.com/api_flower_callback/${templateId}`
                    : `http://localhost:3000/api_flower_callback/${templateId}`
                console.log('Callback URL:', callbackUrl); // 추가된 부분
                // 서버에서 받은 데이터를 바탕으로 주문 페이지로 이동
                const productUrl = `https://agent.maruw.co.kr/mobile/product?agency_idx=2679&user_idx=2930&goods_div_idx=1&menu=none&shop=moiiteeofficial&callback_url=${encodeURIComponent(callbackUrl)}`;

                // 현재 창에서 리디렉션
                window.location.href = productUrl;
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    });

    // 상위 컴포넌트의 ID를 사용하여 요소 선택
    const scrollContainer = document.querySelector('.side_contents');

    if (scrollContainer) {
        scrollContainer.addEventListener('scroll', function () {
            const floatingEditBtn = document.getElementById('editView_flower_floatingBtn');
            
            if (!floatingEditBtn) {
                console.error('Element with ID "editView_flower_floatingBtn" not found.');
                return;
            }

            if (scrollContainer.scrollTop > 0) {
                floatingEditBtn.style.bottom = '32px'; // 아래로 스크롤 시
            } else {
                floatingEditBtn.style.bottom = '-56px'; // 위로 스크롤 시
            }
        });
    } else {
        console.error('Element with ID "scrollContainer" not found.');
    }

}


